const buttons = {
  saveCloseButton: {
    text: 'Save & Close',
  },
  saveAddButton: {
    text: 'Save & Add Another',
  },
  cancelButton: {
    text: 'Cancel',
  },
};

export default buttons;
