import RuleGenerator from '@/utils/RuleGenerator';

const fields = {
  expireDate: {
    label: 'License Expiration',
    rules: new RuleGenerator('License Expiration')
      .setRegEx(/^$|^\d{4}-\d{2}-\d{2}$/)
      .getRules(),
    hint: 'YYYY-MM-DD',
  },
  info: {
    label: 'License Info',
  },
  maxIdentities: {
    label: 'Max Identities',
  },
  algorithm: {
    label: 'Algorithm Name',
    rules: new RuleGenerator('Algorithm Name')
      .setRequired()
      .getRules(),
  },
};

export default fields;
