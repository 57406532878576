<template>
  <v-dialog
    v-model="isOpen"
    width="500"
    persistent
  >
    <v-card>
      <v-card-title class="subheading">
        Manage License
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field label="Biometric Group" v-model="serverGroup.code" disabled />
          <v-select
            :items="biometrics"
            v-bind="fields.algorithm"
            v-model="selectedBiometric"
            item-value="code"
            item-text="code"
            @change="setLicence"
            return-object
          />
          <v-text-field label="Type" v-model="friendlyType" disabled />
          <v-text-field v-bind="fields.expireDate" v-model="license.expireDate" />
          <v-text-field v-bind="fields.info" v-model="license.info" />
          <v-text-field v-bind="fields.maxIdentities" v-model="license.maxIdentities" />
        </v-form>
      </v-card-text>
      <v-layout justify-center>
        <v-card-actions>
          <BaseButton
            v-bind="cancelButton"
            @clicked="cancelClick"
          />
          <BaseButton
            color="accent"
            v-bind="saveAddButton"
            @clicked="saveAddClick"
          />
          <BaseButton
            color="accent"
            v-bind="saveCloseButton"
            @clicked="saveCloseClick"
          />
        </v-card-actions>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { fields, buttons } from '@/components/config/biometricLicense';
import License from '@/models/License';
import Biometric from '@/models/Biometric';
import BiometricMap from '@/utils/BiometricMap';
import serverGroupAlgorithmService from '@/services/ServerGroupAlgorithmService';
import moment from 'moment';

export default {
  name: 'BiometricLicenseDialog',
  props: {
    open: Boolean,
    biometrics: Array,
    serverGroup: Object,
  },
  data: () => ({
    isOpen: false,
    fields,
    ...buttons,
    license: new License({}),
    selectedBiometric: new Biometric({}),
  }),
  computed: {
    ...mapGetters('serverGroupAlgorithms', [
      'getAlgorithmLicense',
    ]),
    friendlyType() {
      if (this.selectedBiometric) {
        const map = BiometricMap.get(this.selectedBiometric.captureType);
        return map.friendly;
      }
      return null;
    },
  },
  watch: {
    open(val) {
      this.isOpen = val;
      if (val) {
        this.reset();
      }
    },
  },
  components: {
    BaseButton: () => import('@/components/base/BaseButton'),
  },
  methods: {
    moment() {
      return moment();
    },
    ...mapActions('serverGroupAlgorithms', [
      'fetchAllBy',
      'associateBiometric',
    ]),
    cancelClick() {
      this.$emit('cancel');
    },
    setLicence(algorithm) {
      this.license = new License(this.getAlgorithmLicense(algorithm.code));
      if (this.license.expireDate) {
        this.license.expireDate = moment.utc(this.license.expireDate).format('YYYY-MM-DD');
      }
    },
    async saveAddClick() {
      if (this.$refs.form.validate()) {
        await this.saveLicense();
        this.reset();
      }
    },
    async saveCloseClick() {
      if (this.$refs.form.validate()) {
        await this.saveLicense();
        this.$emit('cancel');
      }
    },
    async saveLicense() {
      const licenseObj = {
        service: serverGroupAlgorithmService,
        serverGroupCode: this.serverGroup.code,
        algorithmCode: this.selectedBiometric.code,
        license: { licenseData: this.license },
      };
      await this.associateBiometric(licenseObj);
    },
    async reset() {
      this.selectedBiometric = null;
      this.license = new License({});
      await this.fetchAllBy({ service: serverGroupAlgorithmService, key: 'code', fetchBy: this.serverGroup.code });
    },
  },
  mounted() {
    if (this.open) {
      this.isOpen = this.open;
      this.reset();
    }
  },
};
</script>
